import { Links, Scripts, LiveReload, AdminPanel, LosseLink, useRouteError, isRouteErrorResponse } from '@ubo/losse-sjedel'
import CrossHair from '../elements/CrossHair'

export default function PageNotFound() {
  const error = useRouteError()

  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 grid place-items-center lg:px-8">
          <div className="max-w-max h-full mx-auto">
            <main className="flex h-full items-center">{!isRouteErrorResponse(error) ? <Help /> : <NotFound />}</main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}

function Help() {
  const error = useRouteError()
  let errorMessage = 'Unknown error'

  if (error instanceof Error) {
    errorMessage = error.stack
  }

  return (
    <>
      <p className="text-4xl font-extrabold text-site-accent sm:text-5xl">500</p>
      <div className="sm:ml-6">
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">Serverfout</h1>
          <p className="mt-1 text-base text-black/75">
            Er is iets misgegaan op de server. Neem{' '}
            <a className="underline" href="/contact">
              contact
            </a>{' '}
            met ons op zodat we het kunnen oplossen.
          </p>

          <pre className="mt-1 text-red-500 p-2 text-sm">{errorMessage}</pre>
        </div>
        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
          <LosseLink to="/" className="btn">
            Ga naar home
          </LosseLink>
          <LosseLink to="/contact" className="btn--alt">
            Contact
          </LosseLink>
        </div>
      </div>
    </>
  )
}

function NotFound() {
  return (
    <>
      <div className="flex flex-col items-center gap-10 lg:gap-14 relative">
        <CrossHair className="max-sm:opacity-50 w-[320px] sm:w-[440px] animate-fade-up animate-ease-in-out animate-delay-500 absolute left-0 right-0 top-[60px] mx-auto" />
        <p className="text-[170px] lg:text-[250px] animate-fade-up animate-ease-in-out !leading-[0.6] drop-shadow text-transparent border-text font-bold">
          404
        </p>
        <h1 className="text-4xl sm:text-[90px] animate-fade-up animate-delay-75 animate-ease-in-out text-site-base relative leading-[1] text-center font-bold">
          Sorry, <br /> er is geen match.
        </h1>
        <h2 className="text-2xl lg:text-3xl relative max-w-screen-sm text-center animate-fade-up animate-ease-in-out animate-delay-150 font-bold text-site-base">
          De pagina die je probeert te zoeken <br />
          bestaat niet meer of is verhuisd.
        </h2>
        <LosseLink to="/" className="btn--conversion animate-fade-up animate-ease-in-out animate-delay-200 relative">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="01 align center">
              <path
                id="Vector"
                d="M13.838 1.33312C13.4707 1.00255 12.9941 0.819641 12.5 0.819641C12.0059 0.819641 11.5293 1.00255 11.162 1.33312L0.5 10.9291V21.3291C0.5 22.1778 0.837142 22.9917 1.43726 23.5919C2.03737 24.192 2.85131 24.5291 3.7 24.5291H21.3C22.1487 24.5291 22.9626 24.192 23.5627 23.5919C24.1629 22.9917 24.5 22.1778 24.5 21.3291V10.9291L13.838 1.33312ZM15.5 22.5261H9.5V17.5001C9.5 16.7045 9.81607 15.9414 10.3787 15.3788C10.9413 14.8162 11.7044 14.5001 12.5 14.5001C13.2956 14.5001 14.0587 14.8162 14.6213 15.3788C15.1839 15.9414 15.5 16.7045 15.5 17.5001V22.5261ZM22.5 21.3261C22.5 21.6444 22.3736 21.9496 22.1485 22.1746C21.9235 22.3997 21.6183 22.5261 21.3 22.5261H17.5V17.5001C17.5 16.174 16.9732 14.9023 16.0355 13.9646C15.0979 13.0269 13.8261 12.5001 12.5 12.5001C11.1739 12.5001 9.90215 13.0269 8.96447 13.9646C8.02678 14.9023 7.5 16.174 7.5 17.5001V22.5261H3.7C3.38174 22.5261 3.07652 22.3997 2.85147 22.1746C2.62643 21.9496 2.5 21.6444 2.5 21.3261V11.8191L12.5 2.81912L22.5 11.8191V21.3261Z"
                fill="white"
              />
            </g>
          </svg>
          Ga naar homepage
        </LosseLink>
      </div>
    </>
  )
}
